<template>
  <div class="page">
    <el-table :data="ADDetail.links">
      <el-table-column label="图片地址">
        <template slot-scope="scope">
          <div style="display: flex;align-items: center">
            <el-input v-model="scope.row.val" clearable></el-input>
            <el-button @click="showSelect(scope.$index)" style="margin-left: 5px">选择图片</el-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="广告地址">
        <template slot-scope="scope">
          <el-input v-model="scope.row.url" clearable></el-input>
        </template>
      </el-table-column>
      <el-table-column label="排序">
        <template slot-scope="scope">
          <el-input v-model="scope.row.sortnum" clearable></el-input>
        </template>
      </el-table-column>
      <el-table-column label="状态">
        <template slot-scope="scope">
          <el-select v-model="scope.row.state">
            <el-option label="启用" :value="1"></el-option>
            <el-option label="未启用" :value="0"></el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot-scope="scope">
          <el-link :underline="false" type="danger" @click="delLink(scope.$index)">删除</el-link>
        </template>
      </el-table-column>
    </el-table>
    <el-button @click="addLink" style="width: 100%;margin-top: 5px">+</el-button>
    <div style="width: 100%;text-align: right;margin-top: 5px">
      <el-button type="primary" @click="save">保存</el-button>
    </div>
    <fileSelect :visible.sync="showSelecter" title="选择图片" state="image" @confirm="showSelecterSubmit"></fileSelect>
  </div>
</template>

<script>
import {apiDelADDetail, apiEditADDetail, apiGetADDetail} from "@/request/api"
import fileSelect  from "@/views/components/fileSelect"
export default {
  name: "advertisementDetail",
  data() {
    return {
      id:this.$route.query.id,
      ADDetail:{},
      currentIndex:null,
      showSelecter:false
    }
  },
  components: {
    fileSelect
  },
  created() {
    this.getData()
  },
  methods:{
    getData() {
      apiGetADDetail({id:this.id}).then(res => {
        if(res.code == 200){
          this.ADDetail = res.data
        }else{
          this.$message.error(res.message)
        }
      })
    },
    showSelect(index){
      this.currentIndex = index
      this.showSelecter = true
    },
    addLink(){
      this.ADDetail.links.push({
        val:'',
        url:'',
        sortnum:'',
        state:0,
        kink:'image',
        ad_id:this.id
      })
    },
    showSelecterSubmit(data){
      this.ADDetail.links[this.currentIndex].val = data[0].origin
      this.currentIndex = null
    },
    save(){
      apiEditADDetail({child:this.ADDetail.links}).then(res => {
        if(res.code == 200) {
          this.$message.success(res.message)
          this.getData()
        }else {
          this.$message.error(res.message)
        }
      })
    },
    delLink(index) {
      var id = this.ADDetail.links[index].id
      this.$confirm('确定删除该广告链接？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if(id){
          apiDelADDetail({id}).then(res => {
            if(res.code == 200){
              this.$message.success(res.message)
              this.ADDetail.links.splice(index,1)
            }else{
              this.$message.error(res.message)
            }
          })
        }else{
          this.ADDetail.links.splice(index,1)
        }
      })
    }
  }
}
</script>

<style scoped>
.page {
  background-color: #FFF;
  padding: 20px;
}
</style>
